import Button from '@/components/atoms/Button';
import Modal from '@/components/atoms/Modal';
import SpinnerWrapper from '@/components/atoms/SpinnerWrapper';
import useAuth from '@/contexts/AuthProvider';
import { AUTH } from '@/services/apis/apiConstrant';
import AroggaAPI from '@/services/apis/AroggaAPI';
import useTimer from '@/services/hooks/useTimer';
import Spinner from '@labtest/presentation/atoms/Loaders/Spinners';
import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import styles from './AccountVerificationProcess.module.scss';
import Input from '@/components/atoms/Input';
import cls from 'classnames';
import { validPhoneNumber } from '@/services/utils/helper';
import { MdOutlineClose } from 'react-icons/md';
import { useRouter } from 'next/navigation';

const LoadingContent = () => (
	<div className='d-flex flex-column justify-center items-center gap-20 p-20 border-b1-grey200 border-radius-5'>
		<SpinnerWrapper>
			<Spinner size={50} />
		</SpinnerWrapper>
	</div>
);

const AccountVerificationProcess = ({ isOpen, onClose, data, from, onfinish, redirect = false }) => {
	const { type, identifier: phoneOrEmail } = data;
	const [identifier, setIdentifier] = useState(phoneOrEmail);
	const [step, setStep] = useState(null);
	const [loading, setLoading] = useState(false);
	const [responseData, setResponseData] = useState(null);
	const [canPlaceOrder, setCanPlaceOrder] = useState(false);
	const router = useRouter();
	const timerOnFinished = () => {
		stopTimer();
	};
	const { minutes, seconds, startTimer, stopTimer, isActive, resetTimer, restartTimer } = useTimer(
		30,
		timerOnFinished
	);

	const handleVerification = async () => {
		setLoading(true);
		const { data, status, message } = await AroggaAPI.post(AUTH.SOCIAL_OTP_SEND, {
			identifier: from !== 'account' ? identifier : phoneOrEmail,
			for: type
		});
		if (status === 'fail') {
			if (data.isUserAlreadyExit) {
				setStep('existing_account');
				setResponseData({
					description: message,
					title: 'Account already exit'
				});
				setCanPlaceOrder(false);
			}
			// TODO:: handle error
		} else {
			setStep('otp_verification');
			setCanPlaceOrder(true);
		}

		setLoading(false);
	};

	const handelAuthSmsSend = async () => {
		setLoading(true);
		const res = await AroggaAPI(AUTH.SOCIAL_OTP_SEND, {
			method: 'POST',
			body: {
				identifier: from !== 'account' ? identifier : phoneOrEmail,
				for: type,
				isConfirm: true
			}
		});
		if (res.status === 'success') {
			restartTimer();
		}
		setLoading(false);
		return res;
	};
	useEffect(() => {
		if (isOpen && from === 'account') {
			handleVerification();
		} else {
			setStep('verify_mobile');
		}
	}, [isOpen]);
	const renderContent = (response) => {
		const commonProps = {
			identifier,
			response,
			setStep,
			onClose,
			startTimer,
			stopTimer,
			isActive,
			restartTimer,
			handelAuthSmsSend,
			minutes,
			seconds,
			loadingContent: <LoadingContent />,
			loading,
			setLoading,
			setIdentifier,
			handleVerification,
			onfinish,
			canPlaceOrder,
			redirect,
			router,
			type
		};
		switch (step) {
			case 'existing_account':
				return <ExistingAccountBody {...commonProps} />;
			case 'otp_verification':
				return <OptVerificationBody {...commonProps} />;
			case 'verify_mobile':
				return <VerifyMobile {...commonProps} />;
			default:
				return null;
		}
	};
	useEffect(() => {
		phoneOrEmail && setIdentifier(phoneOrEmail);
	}, [phoneOrEmail]);
	return (
		<>
			{from === 'account' ? (
				<Modal
					title=''
					isOpen={isOpen}
					footer={null}
					width={400}
					onClose={() => {
						console.log('close');
					}}>
					{!loading ? <>{renderContent(responseData)}</> : <LoadingContent />}
				</Modal>
			) : !loading ? (
				<>{renderContent(responseData)}</>
			) : (
				<LoadingContent />
			)}
		</>
	);
};

export default AccountVerificationProcess;

const ExistingAccountBody = ({ identifier, response, setStep, onClose, handelAuthSmsSend, restartTimer }) => {
	const cssAppliedContent = (body) => `
    <div>
      <style>
        b {
          font-weight: bold;
        }
      </style>
      ${body}
    <div>
    `;

	const onLogin = async () => {
		const res = await handelAuthSmsSend();

		if (res.status === 'success') {
			setStep('otp_verification');
			restartTimer();
		}
	};

	return (
		<>
			<div className='d-flex flex-column justify-center items-center gap-20 p-20 border-b1-grey200 border-radius-5'>
				<svg width='58' height='59' viewBox='0 0 58 59' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<circle cx='29' cy='29.5' r='29' fill='#FFFAEB' />
					<path
						d='M14.3333 42.1667L29 16.8333L43.6667 42.1667H14.3333ZM18.9333 39.5H39.0667L29 22.1667L18.9333 39.5ZM29 38.1667C29.3778 38.1667 29.6947 38.0387 29.9507 37.7827C30.2058 37.5275 30.3333 37.2111 30.3333 36.8333C30.3333 36.4555 30.2058 36.1391 29.9507 35.884C29.6947 35.628 29.3778 35.5 29 35.5C28.6222 35.5 28.3058 35.628 28.0507 35.884C27.7947 36.1391 27.6667 36.4555 27.6667 36.8333C27.6667 37.2111 27.7947 37.5275 28.0507 37.7827C28.3058 38.0387 28.6222 38.1667 29 38.1667ZM27.6667 34.1667H30.3333V27.5H27.6667V34.1667Z'
						fill='#F79009'
					/>
				</svg>

				<div className='text-18 text-grey900 fw-600 text-center'>Account already exit</div>
				<div className='text-14 text-grey600 fw-400 text-center lh-22'>
					<span dangerouslySetInnerHTML={{ __html: cssAppliedContent(response.description) }} />
				</div>
			</div>

			<div className='d-flex justify-center gap-12 p-20'>
				<Button
					style={{
						width: '50%'
					}}
					key='back'
					onClick={() => {
						onClose();
					}}
					type='light'
					label='NO'
				/>

				<Button
					style={{
						width: '50%'
					}}
					key='submit'
					type='primary'
					onClick={() => {
						onLogin();
					}}
					label='Login'
				/>
			</div>
		</>
	);
};

const OptVerificationBody = ({
	identifier,
	handelAuthSmsSend,
	minutes,
	seconds,
	isActive,
	onClose,
	onfinish,
	canPlaceOrder,
	redirect,
	router,
	type
}) => {
	const [code, setCode] = useState('');
	const { userLoginAction } = useAuth();
	console.log('canPlaceOrder', canPlaceOrder);

	const handleLogin = async (redirect) => {
		if (!code) {
			return;
		}

		const res = await AroggaAPI(AUTH.SOCIAL_OTP_VERIFY, {
			method: 'POST',
			body: {
				for: type || 'phone',
				otp: code,
				identifier,
				...(!canPlaceOrder && { isConfirm: true })
			}
		});

		if (res.status === 'success') {
			userLoginAction(res.data);
			onClose();
			if (redirect === true) {
				router.push('/checkout');
			}
		}

		if (res.status === 'fail') {
		}
	};
	const time = `${minutes}:${seconds}`;

	return (
		<div className='border-radius-5 '>
			<div className='border-b1-grey200'>
				<div className='d-flex justify-space-between w-full border-b1-grey200 pb-20 p-24'>
					<div className='d-flex flex-column gap-5 '>
						<div className='text-20 text-grey900 fw-500'>Verification</div>
						<div className='text-14 text-grey900'>
							Enter the OTP sent to <span className='text-primary'>{identifier}</span>
						</div>
					</div>
					<div className='cursor-pointer' onClick={onClose}>
						<MdOutlineClose size={24} />
					</div>
				</div>
				<div className='p-24 d-flex flex-column gap-20 justify-center items-center'>
					<CodeInputBox onFinish={setCode} />
					<div className='d-flex flex-column justify-center gap-12 text-grey600 text-14'>
						<div className='text-center'>Have’t receive the code yet?</div>
						<div className='text-center'>
							{isActive ? (
								<div className='text-center'>
									Resend Code: <span className='text-primary'>({time})</span>
								</div>
							) : (
								<div className='text-primary  cursor-pointer' onClick={handelAuthSmsSend}>
									Resend Code
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className='d-flex justify-center gap-12 p-20'>
				<Button
					className='w-full'
					disabled={isEmpty(code)}
					key='submit'
					type='primary'
					onClick={() => {
						onfinish && canPlaceOrder ? onfinish() : handleLogin(redirect);
					}}
					label='Verify'
				/>
			</div>
		</div>
	);
};

const CodeInputBox = ({ onFinish }: { onFinish: (code: string | null) => void }) => {
	const [values, setValues] = useState<string[]>(['', '', '', '']);
	const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

	const handleChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		if (/^[0-9]?$/.test(value)) {
			const newValues = [...values];
			newValues[index] = value;
			setValues(newValues);

			if (value && index < 3) {
				inputRefs.current[index + 1]?.focus(); // Automatically focus next input
			}
		}
	};

	const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Backspace' && !values[index] && index > 0) {
			inputRefs.current[index - 1]?.focus(); // Automatically focus previous input on backspace
		}
	};
	useEffect(() => {
		const code = values.join('');
		if (code.length === 4 && values.every((val) => val !== '')) {
			onFinish(code);
		} else {
			onFinish(null);
		}
	}, [values, onFinish]);

	return (
		<div style={{ display: 'flex', gap: '10px' }}>
			{values.map((value, index) => (
				<input
					key={index}
					type='text'
					placeholder='0'
					value={value}
					onChange={(e) => handleChange(index, e)}
					onKeyDown={(e) => handleKeyDown(index, e)}
					maxLength={1}
					ref={(el) => (inputRefs.current[index] = el)}
					className={styles.codeInput}
				/>
			))}
		</div>
	);
};

const VerifyMobile = ({ identifier, onClose, setIdentifier, handleVerification }) => {
	const handleChange = (name) => (e: React.ChangeEvent<HTMLInputElement>) => {
		if (name === 'phone') {
			if (isNaN(Number(e.target.value))) {
				return;
			}
			let newValue = e.target.value;

			if (newValue.startsWith('+88')) {
				newValue = newValue.substring(3);
			} else if (newValue.startsWith('88')) {
				newValue = newValue.substring(2);
			}
			if (newValue.length > 11) {
				newValue = newValue.slice(0, 11);
			}

			setIdentifier(newValue);
		} else {
			setIdentifier(e.target.value);
		}
	};
	const handleSendOtp = async () => {
		const phone_number = identifier || identifier;
		const validNumber = validPhoneNumber(phone_number);
		if (validNumber) {
			await handleVerification();
		}
	};

	return (
		<div className='border-radius-5 '>
			<div className='border-b1-grey200'>
				<div className='d-flex justify-space-between w-full border-b1-grey200 pb-20 p-24'>
					<div className='d-flex flex-column gap-5 '>
						<div className='text-20 text-grey900 fw-500'>Verify account number for COD</div>
					</div>
					<div className='cursor-pointer' onClick={onClose}>
						<MdOutlineClose size={24} />
					</div>
				</div>
				<div>
					<div className='px-24 pt-24 d-flex flex-column gap-5'>
						<label className='text-grey900 fw-500 text-16 mb-5'>Phone Number *</label>
						<div style={{ position: 'relative' }}>
							<div className={cls(styles.absulate_prefix)}>
								<span className={cls(styles.prefix)}>+88</span>
							</div>
							<Input
								id='phone'
								style={{
									paddingLeft: 45,
									height: 50
								}}
								className={cls(styles.input_phone)}
								type='text'
								defaultValue=''
								onChange={handleChange('phone')}
								placeholder=''
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										handleSendOtp();
									}
								}}
							/>
						</div>
					</div>
					<div className='px-24 pt-10 pb-24 d-flex text-grey700 gap-5'>
						Skip number verification—just
						<span className='text-primary cursor-pointer fw-500' style={{ textDecoration: 'underline' }}>
							Pay Online
						</span>
					</div>
				</div>
			</div>

			<div className='d-flex justify-center gap-12 p-20'>
				<Button
					className='w-full'
					key='submit'
					size='large'
					type='primary'
					onClick={() => {
						handleSendOtp();
					}}
					label='Send OTP'
				/>
			</div>
		</div>
	);
};

import { IM_PAYMENTS_ICONS } from '@/assets/images';
import Button from '@/components/atoms/Button';
import Card from '@/components/atoms/Card';
import CheckBox from '@/components/atoms/CheckBox';
import Input from '@/components/atoms/Input';
import Modal from '@/components/atoms/Modal';
import Radio from '@/components/atoms/Radio';
import SpinnerWrapper from '@/components/atoms/SpinnerWrapper';
import { useAlert } from '@/contexts/AlertProvider';
import useAuth from '@/contexts/AuthProvider';
import useCart from '@/contexts/CartProvider';
import AroggaAPI from '@/services/apis/AroggaAPI';
import AroggaSWR from '@/services/apis/AroggaSWR';
import { API_CART } from '@/services/apis/apiConstrant';
import usePixalEvent from '@/services/event/usePixalEvent';
import { newWindowLoad } from '@/services/utils/helper';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { MdHomeFilled } from 'react-icons/md';

import { isEmpty } from 'lodash';
import styles from './CheckoutModal.module.scss';
import AccountVerificationProcess from '@/components/molecules/AccountVerification/AccountVerificationProcess';
const CheckoutModal = ({ isOpen, onClose, carts, setIsOpen, scheduleId, deliveryOption }) => {
	const [startVerification, setStartVerification] = useState<boolean>(false);
	const router = useRouter();
	const [isOrderPlaceing, setIsOrderPlaced] = useState(false);
	const { setCarts, setDeliveryAddress, setDrawerOpen, setIsPayementLoading, getCart } = useCart();
	const [loading, setLoading] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState(null);
	const [subscription, setSubscription] = useState(false);
	const [note, setNote] = useState('');
	const { getUserProfile, user } = useAuth();
	const {
		data,
		isLoading,
		mutate,
		status,
		message = ''
	} = AroggaSWR(`${API_CART.CHECKOUT_INIT}?_delivery_option=${deliveryOption}`);

	const alert = useAlert();
	const { EventBeginCheckout, EventPurchase } = usePixalEvent();

	const handlePlaceOrder = async () => {
		setIsOrderPlaced(true);
		setLoading(true);
		const o_data = {
			po_payment_method: paymentMethod,
			po_delivery_option: deliveryOption,
			po_delivery_shift_schedule_id: scheduleId,
			po_subscription_frequency: subscription ? 'MONTHLY' : null,
			po_user_notes: note || null
		};

		const { data, status, message }: any = await AroggaAPI.post(API_CART.ORDER_CREATE_FROM_CART, o_data);
		if (status === 'success') {
			EventPurchase({
				currency: 'BDT',
				coupon: data?.d_code || '',
				items: data?.products?.map((item) => ({
					item_id: item?.p_id,
					quantity: item?.qty,
					item_name: item?.p_name
				})),
				value: data?.po_payable_total,
				contents: data?.products,
				address: data?.uc_address
			});
			const isOnlinePayment = paymentMethod === 'online' && data?.payment_url;
			setIsOrderPlaced(false);
			getUserProfile();
			setIsOpen(false);
			setLoading(false);
			setCarts([]);
			setDeliveryAddress(data?.user_default_location);
			setDrawerOpen(false);
			alert.orderSuccess({
				title: 'Congratulations!',
				message: message,
				confirmText: 'Track Order',
				onConfirm: () => {
					router.push(`/account/orders/${data?.po_id}`);
				}
			});

			if (paymentMethod === 'online' && data?.payment_url) {
				newWindowLoad(data?.payment_url, data?.po_id, router, setIsPayementLoading, alert);
			}
		} else {
			//check message include Coupon is expired
			if (message?.includes('Coupon is expired')) {
				alert.warning({
					title: 'Warning',
					message: message || 'Something went wrong',
					confirmText: 'Proceed',
					onConfirm: () => {
						handlePlaceOrder();
					},
					cancelText: 'Cancel',
					onCancel: () => {
						setLoading(false);
						setIsOrderPlaced(false);
						setIsOpen(false);
						getCart();
					}
				});

				return;
			}
			alert.error({
				title: 'Error',
				message: message,
				confirmText: 'Go to Order',
				cancelText: 'Cancel'
			});
			setLoading(false);
			setIsOrderPlaced(false);
		}
	};
	const isCodAvailable = data?.po_is_cod_available;
	const isCodAvailableCompireMaxCod = data?.po_payable_total <= data?.maxCod;

	useEffect(() => {
		if (status == 'fail' && message?.includes('Coupon is expired')) {
			alert.warning({
				title: 'Warning',
				message: message || 'Something went wrong',
				confirmText: 'Proceed',
				onConfirm: () => {
					mutate();
				},
				cancelText: 'Cancel',
				onCancel: () => {
					setIsOpen(false);
				}
			});
		}
		if (!data?.po_is_cod_available) {
			setPaymentMethod('online');
		} else {
			setPaymentMethod('cod');
		}

		data &&
			EventBeginCheckout({
				currency: 'BDT',
				coupon: data?.d_code || '',
				items: data?.products?.map((item) => ({
					item_id: item?.p_id,
					quantity: item?.qty,
					item_name: item?.p_name
				})),
				value: data?.po_payable_total,
				contents: data?.products,
				address: data?.uc_address
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, status]);
	useEffect(() => {
		const channel = new BroadcastChannel('cart_channel');

		channel.onmessage = (event) => {
			mutate(event.data);
		};

		return () => {
			channel.close();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const showPamynetOption = data?.po_payable_total > 0;
	const isNeedToVerifyMobile = !data?.is_mobile_verified && paymentMethod === 'cod';

	return (
		<Modal
			isOpen={isOpen}
			onClose={() => {
				onClose();
				setPaymentMethod(null);
				setSubscription(false);
				setNote('');
			}}
			footer={null}
			width={550}
			maskClosable={false}
			title={startVerification ? null : 'Checkout'}
			bodyStyle={{
				borderRadius: '15px',
				backgroundColor: startVerification ? 'transparent' : '#F7FAFC',
				padding: startVerification ? 0 : '20px'
			}}>
			{startVerification && (
				<AccountVerificationProcess
					isOpen={startVerification}
					onClose={() => {
						setStartVerification(false);
					}}
					data={{
						type: 'mobile',
						identifier: user.u_mobile
					}}
					from='checkout'
					onfinish={() => {
						handlePlaceOrder();
					}}
				/>
			)}
			{!startVerification && (
				<SpinnerWrapper loading={loading || isLoading}>
					<Card bordered={false} bodyStyle={{}} title='Shipping Address' size='small'>
						<div className='d-flex justify-content-center gap-10'>
							<MdHomeFilled size={24} style={{ color: '#718096' }} />
							<div className='d-flex flex-column gap-3'>
								<span>{carts?.uc_address?.ul_name}</span>
								<span>{carts?.uc_address?.ul_mobile}</span>
								<span>{carts?.uc_address?.ul_location}</span>
							</div>
						</div>

						<Input
							className={styles.input_notes}
							placeholder='Write here any additional info'
							type='textArea'
							multiline
							maxLength={200}
							rows={2}
							onChange={(e) => {
								setNote(e.target.value);
							}}
						/>
					</Card>
					<div className='pb-20'>
						<CheckBox
							inputStyle={{
								borderColor: '#CBD5E0'
							}}
							label='আমি প্রতি মাসেই কিনতে চাই'
							onChange={(e) => {
								setSubscription(e);
							}}
						/>
						{subscription && (
							<div className='pl-27 text-15 mt-20'>
								আজ থেকে ২৮ দিন পরে আপনার নামে আরেকটি অর্ডার তৈরী হবে, আমাদের ফার্মাসিস্ট আপনাকে ফোন করে অর্ডারটি
								কনফার্ম করবেন। আপনি তখন চাইলে যেকোন পরিবর্তন করে নিতে পারবেন ওই অর্ডারে।
							</div>
						)}
					</div>
					<Card
						size='small'
						bordered={false}
						bodyStyle={{
							padding: '20px'
						}}
						headerStyle={{
							borderRadius: '12px 12px 0px 0px',
							backgroundColor: '#E5F6F5'
						}}
						title={
							<>
								<span className='text-primary'>Amount to be paid</span>
							</>
						}
						extra={<span className='text-primary fw-600'>৳{data?.po_payable_total}</span>}>
						{showPamynetOption ? (
							<div className='d-flex flex-column gap-20'>
								<Radio.Group
									name='address'
									style={{
										display: 'flex',
										flexDirection: 'column',
										gap: 20
									}}
									defaultValue={paymentMethod}
									onChange={(e) => {
										const selectedValue = e.target.value;
										if (!isCodAvailable && selectedValue === 'cod') {
											alert.warning({
												title: 'Warning',
												message: 'COD is not available'
											});
											setPaymentMethod('online');
										} else {
											setPaymentMethod(e.target.value);
										}
									}}>
									<Radio
										value='cod'
										disabled={!isCodAvailable}
										onClick={() => {
											const selectedValue = 'cod';
											if (!isCodAvailable && selectedValue === 'cod') {
												alert.warning({
													title: 'Warning',
													message: 'COD is not available'
												});
												setPaymentMethod('online');
											} else {
												setPaymentMethod('cod');
											}
										}}
										style={
											!isCodAvailable
												? {
														cursor: 'not-allowed'
													}
												: {}
										}>
										<span
											className='text-grey900 fw-700'
											style={isCodAvailable ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}
											onClick={() => {
												if (!isCodAvailable) {
													alert.warning({
														title: 'Warning',
														message: 'COD is not available'
													});
												}
											}}>
											{isCodAvailable ? (
												'Cash on delivery'
											) : (
												<>
													<div className='text-grey500'>Cash on delivery</div>
													{!isCodAvailableCompireMaxCod && (
														<div className=' text-14 fw-400 text-grey500'>
															Maximun amount for COD is ৳ {data?.maxCod}
														</div>
													)}
												</>
											)}
										</span>
										{isNeedToVerifyMobile && (
											<div className='text-14 bg-error_bg rounded-10 p-10 text-error d-flex justify-content-between'>
												Please verify account phone number for one time to COD.
												<Button
													size='small'
													type='warning'
													className='rounded-10'
													onClick={() => {
														setStartVerification(true);
													}}>
													Verify
												</Button>
											</div>
										)}
									</Radio>
									<Radio
										value='online'
										onClick={() => {
											setPaymentMethod('online');
										}}></Radio>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											marginTop: -40,
											marginLeft: 20
										}}>
										<Image
											src={IM_PAYMENTS_ICONS.src}
											width={413}
											height={150}
											alt='payment icons'
											unoptimized
										/>
									</div>
								</Radio.Group>
							</div>
						) : (
							<div className='d-flex flex-column gap-20 fw-500'>
								{user.u_cash_balance > 0 ? (
									<span>Your Payment will be deducted from your Arogga Cash Balance</span>
								) : (
									<div className='text-14 text-grey500'>
										Our customer service will call you to confirm your order and payment amount
									</div>
								)}
							</div>
						)}

						{paymentMethod === 'online' && (
							<>
								<div className='p-20 bg-primary_bg rounded-10 mt-10 text-grey-600 text-14'>
									<FaInfoCircle />{' '}
									<span className='ml-10'>
										<span>
											{data?.should_open_popup_for_online_payment
												? 'A payment window will appear after placing order.'
												: 'You will be given an option to pay online after order is packed and ready for delivery.'}
										</span>
									</span>
								</div>
							</>
						)}
						{!isEmpty(data?.note) && (
							<>
								<div className='p-20 bg-error_bg rounded-10 mt-10 text-grey-600 text-14'>
									<FaInfoCircle
										style={{
											color: 'red'
										}}
									/>{' '}
									<span className='ml-10'>
										<span>{data.note}</span>
									</span>
								</div>
							</>
						)}
					</Card>
					<p
						className='text-grey600 mb-5'
						style={{
							fontSize: 14,
							lineHeight: '160%'
						}}>
						By continuing you agree to{' '}
						<a href='/page/tos' target='_blank' className='text-primary fw-500'>
							Terms &amp; Conditions
						</a>
						{','}
						<a href='/page/privacy' target='_blank' className='text-primary fw-500'>
							Privacy Policy
						</a>{' '}
						&amp;{' '}
						<a href='/page/return-policy' target='_blank' className='text-primary fw-500'>
							Refund-Return Policy
						</a>
					</p>

					<Button disabled={isOrderPlaceing} type='primary' className='w-full' onClick={handlePlaceOrder}>
						Place Order
					</Button>
				</SpinnerWrapper>
			)}
		</Modal>
	);
};

export default CheckoutModal;
